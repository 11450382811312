import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
import { Helmet } from "react-helmet";
import "../../App.css";
import homeLogo from "../../Assets/home_image.gif"; // Ensure correct path

function Home() {
  return (
    <section>
      <Helmet>
        <meta
          name="google-site-verification"
          content="iyBpTceLyb4DKFtwFifPjFdr7DDLrVjSL2KkCOrZMto"
        />
      </Helmet>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row className="align-items-center">
            <Col md={7} className="home-header">
              <h1 className="heading mb-3">
                Hi There!{" "}
                <span className="wave" role="img" aria-label="wave">
                  👋🏻
                </span>
              </h1>

              <h1 className="heading-name mb-4">
                I'm
                <strong className="main-name"> Harsh </strong>
              </h1>

              <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div>
            </Col>

            <Col md={5} className="text-center">
              <img
                src={homeLogo}
                alt="Home Animation"
                className="img-fluid"
                style={{
                  maxHeight: "450px",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
