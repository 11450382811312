// Techstack.js

import React from "react";
import { Col, Row } from "react-bootstrap";
import "./Techstack.css"; // Import your CSS file for styling

function Techstack() {
  const skills = [
    { name: "Python", category: "Programming" },
    { name: "DSA", category: "Programming" },
    { name: "Computer Vision", category: "Programming" },
    { name: "Machine Learning", category: "Programming" },
    { name: "Deep Learning", category: "Programming" },
    { name: "GenAI", category: "Programming" },
    { name: "Tensor Flow", category: "Library" },
    { name: "Keras", category: "Library" },
    { name: "PyTorch", category: "Library" },
  ];

  return (
    <Row className="skills-box">
      {skills.map((skill, index) => (
        <Col key={index} xs={4} md={2} className={`tech-box ${skill.category}`}>
          <div>
            {skill.name}
          </div>
          <div className="category-text">
            {skill.category}
          </div>
        </Col>
      ))}
    </Row>
  );
}

export default Techstack;
