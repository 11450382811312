import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/harsh.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { ReactComponent as GoogleScholarIcon } from "./google-scholar.svg"; // Import your Google Scholar SVG

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              Greetings! I am a <i><b className="purple"> M.Tech student in the Department of Management Science (DoMS)</b></i> at IIT Kanpur, with a specialized focus on computer vision research <br />
              <br />
              I'm Proficient in computational tools such as <i><b className="purple">Python, Deep Learning, Machine Learning algorithms.</b></i> My experience in competitive programming has further refined my analytical and problem-solving abilities. <br />
              <br />
              {/* Holding an <i><b className="purple"> <a href="https://www.credly.com/badges/738deff8-fdb4-433a-a610-d637a469895f" target="_blank" rel="noreferrer">Engineer-in-Training (E.I.T.)</a></b></i> certification further underscores my commitment to professional growth. */}
              Holding certifications in Convolutional Neural Networks (CNN), Natural Language Processing (NLP), and Machine Learning further underscores my commitment to professional growth.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" style={{ width: "75%" }} />
            </Tilt>
          </Col>

        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/harsh78621"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/harsh-iitkanpur"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/h.a.a.r.s.h"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;

// I am currently pursuing an M.Tech in the Department of Management Science (DoMS) at IIT Kanpur, with a specialized focus on computer vision research. My academic background bridges the gap between technology and management, providing me with a comprehensive understanding of both the technical and strategic dimensions of the field.

// I have developed a strong proficiency in Python, deep learning, and machine learning algorithms, which I have applied extensively in my research projects. My experience in competitive programming has further refined my analytical and problem-solving abilities, enabling me to tackle complex challenges effectively.

// I hold certifications in Convolutional Neural Networks (CNN), Natural Language Processing (NLP), and Machine Learning, earned through courses provided by *deeplearning.ai* on the *Coursera* online learning platform. These certifications have deepened my knowledge and practical skills in these crucial areas of artificial intelligence.

// In addition to my research expertise, I possess a robust skill set essential for a career in data science, including:

// - *Data Analysis*
// - *Exploratory Data Analysis (EDA)*
// - *Data Preprocessing*
// - *Statistical Modeling*
// - *Data Visualization*
// - *Big Data Technologies*

// My goal is to leverage these skills and my research experience to drive meaningful insights and contribute innovative solutions in the field of data science.