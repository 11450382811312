import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import internGif from "../../Assets/Projects/intern.gif";
import QnA from "../../Assets/Projects/QnA.gif";
import anamoly from "../../Assets/Projects/Anamoly.gif";
import gnn from "../../Assets/Projects/GNN.gif";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white", marginBottom: "100px"}}>
          Here are a few projects I've worked on recently.
        </p>
        
        {/* Internship Section */}
        <h2 className="project-heading" style={{ marginTop: "40px" }}>
          <strong className="purple">Research Internship</strong>
        </h2>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={internGif}
              // imgPath2={P4}
              isBlog={false}
              title="Video-Based Blepharospasm Detection"
              description="Developed a method to identify blepharospasm from 144 videos using three key features, including Blink Normalness. Achieved 99.8% accuracy in eye state prediction using a CNN model and tested six classification models. Attained 94.44% overall accuracy, with 100% recall for blepharospasm, outperforming other methods like Mediapipe and ResNet50."
              ghLink="https://github.com/harsh78621/Video-Based-Blepharospasm-Detection"
              // demoLink="https://huggingface.co/spaces/haarshh/QnA_Research_Paper"
            />
          </Col>
        </Row>

        {/* Self-Based Projects Section */}
        <h2 className="project-heading">
          <strong className="purple">Key Projects</strong>
        </h2>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={QnA}
              isBlog={false}
              title="Research Paper Analyser"
              description="Developed a scalable system for extracting and analyzing text, images, and tables from research papers, using Tesseract-OCR, Gemini, and Camelot. Enhanced PDF extraction with parallel processing, tokenization, and POS tagging. Deployed the model on Hugging Face with Streamlit for real-time interaction."
              ghLink="https://github.com/harsh78621/Research-Paper-Analyzer"
              demoLink="https://huggingface.co/spaces/haarshh/QnA_Research_Paper"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={anamoly}
              isBlog={false}
              title="Timeseries Anomaly Detection Model in Streaming, Online Applications"
              description="Developed a time-series anomaly detection model using the Numenta Anomaly Benchmark (NAB) corpus. Processed 4,032 data points over 14 days with 288 timesteps per day, identifying 394 anomalies based on a reconstruction error threshold of 0.1233."
              ghLink="https://github.com/harsh78621/Timeseries-anomaly-detection-model-in-streaming-online-applications"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={gnn}
              isBlog={false}
              title="Traffic Condition Forecasting with Graph Neural Networks and LSTM"
              description="Engineered a neural network combining Graph Neural Networks and LSTM layers to forecast traffic using the PeMSD7 dataset. Tested custom graph convolution on 228 nodes and 1,664 edges. Achieved a MAE of 0.1837, outperforming the naive forecast of 0.1945."
              ghLink="https://github.com/harsh78621/Traffic-Condition-Forecasting-with-Graph-Neural-Networks-and-LSTM"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              // imgPath={P2}
              isBlog={false}
              title="BookMovie Recommendation Bot"
              description="Developed an interactive chatbot using retrieval-augmented generation (RAG) and generative AI. Plot summaries from 16,559 books and 42,306 movies were embedded using HuggingFace sentence-transformers and stored in a FAISS vector store from langchain. The chatbot retrieves similar books and movies, combines them with user queries, and generates responses using the Gemini model. Deployed on Streamlit, it provides accurate, contextually relevant responses by leveraging advanced NLP techniques and embedding models."
              ghLink="https://github.com/harsh78621/BookMovie-Recommendation-Bot"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              // imgPath={P2}
              isBlog={false}
              title="Image Caption Generator"
              description="Used EfficientNetB0 to extract features from the Flickr8k dataset containing 8,000 images paired with five captions each. A custom Transformer was implemented, with the encoder applying multi-head self-attention and dense layers, while the decoder generated captions using cross-attention, feed-forward layers, and positional encoding. Loss and accuracy were averaged across multiple captions per image, resulting in a model that achieved 0.56 accuracy, effectively managing diverse captions and contextual representation."
              ghLink="https://github.com/harsh78621/Image-Caption-Generator"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              // imgPath={P2}
              isBlog={false}
              title="NLP-Based Clustering and Topic Modelling for Edgar Allan Poe’s Short Stories"
              description="Analyzed Edgar Allan Poe's 70 short stories using NLP techniques like tokenization, stemming, and Word2Vec embeddings. Hierarchical clustering and t-SNE were applied to visualize relationships between stories, with Latent Dirichlet Allocation (LDA) extracting dominant topics. A K-Nearest Neighbors (KNN) recommendation system, based on Word2Vec similarity, effectively suggested related stories, uncovering thematic connections and providing insights into recurring patterns and styles in Poe’s work."
              ghLink="https://github.com/harsh78621/NLP-Based-Clustering"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
