// Techstack.js
import React from "react";
import Card from "react-bootstrap/Card";
import { Col, Row } from "react-bootstrap";
import "./RelevantCoursework.css"; // Import your CSS file for styling

function Techstack() {
  const skills = [
    { name: "Applied Machine Learning", category: "Programming" },
  { name: "Probability & Statistics", category: "Analysis" },
  { name: "Statistical Modelling for Business Analytics (SMBA)", category: "Analysis" },
  { name: "Marketing Research", category: "Analysis" },
  { name: "Operations Research for Management", category: "Analysis" },
  { name: "Introduction to Computing", category: "Programming" },
  ];

  return (
    
      <Row className="skills-box">
        {skills.map((skill, index) => (
          <Col key={index} xs={4} md={2} className={`tech-box ${skill.category}`}>
            <div>
              {skill.name}
            </div>
            <div className="category-text">
              {skill.category}
            </div>
          </Col>
        ))}
      </Row>
      
  );
}

export default Techstack;
