import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Computer Vision",
          "Machine Learning/Artificial Intelligence",
          "Deep Learning", 
          "Data Science Enthusiast"
          
        ],
        autoStart: true,
        loop: true,
        typeSpeed: 100, // Adjust this value to make typing faster
        deleteSpeed: 40, // Adjust this value to make deleting faster
      }}
    />
  );
}

export default Type;
