// Certifications.js

import React from "react";
import Card from "react-bootstrap/Card";
import { Container, Row, Col } from "react-bootstrap";
import "./Certifications.css"; // Import your new CSS file

const certifications = [
  {
    title: "Natural Language Processing with Classification and Vector Spaces",
    link: "https://www.coursera.org/account/accomplishments/verify/ANBSM0FUPS0G",
  },
  {
    title: "Natural Language Processing with Probabilistic Models",
    link: "https://www.coursera.org/account/accomplishments/verify/WYEOHZB8LVVS",
  },
  {
    title: "Advanced Learning Algorithms by DeepLearning.AI",
    link: "https://www.coursera.org/account/accomplishments/verify/3HXQTU7U6E7R",
  },
  {
    title: "Convolutional Neural Networks by DeepLearning.AI",
    link: "https://www.coursera.org/account/accomplishments/verify/BAD4HXB8LAXE",
  },
  {
    title: "Improving Deep Neural Networks: Hyperparameter Tuning, Regularization and Optimization",
    link: "https://www.coursera.org/account/accomplishments/certificate/4GPCJ8ZMDCTQ",
  },
];

function Certifications() {
  return (
    <Container>
      <h1 style={{ fontSize: "2.1em", zIndex: 2, paddingTop: "20px", paddingBottom: "20px", textAlign: "center"  }}>
        <strong className="purple">Certifications </strong> and <strong className="purple">Licenses </strong>
      </h1>
      <Row className="certifications-box">
        {certifications.map((certification, idx) => {
          let title = certification.title;
          let by = "";

          if (certification.title.includes(" by ")) {
            [title, by] = certification.title.split(" by ");
            by = " by " + by;
          }

          return (
            <Col key={idx} md={4}>
              <div className="certification-card">
                <h5>{title}</h5>
                <p>{by}</p>
                <a className="certification-link" href={certification.link} target="_blank" rel="noopener noreferrer">
                  Click here
                </a>
                <div className="certification-category">{/* Add category here if needed */}</div>
              </div>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}

export default Certifications;