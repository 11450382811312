import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Particle from '../Particle';
import emailjs from 'emailjs-com';

function Contact() {
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const handleSubmit = (e) => {
      e.preventDefault();
  
      // Get your user ID and template ID from emailJS dashboard: https://dashboard.emailjs.com
      const serviceID = 'service_iqcoai8';
      const templateID = 'template_7da7z7b';
      const userID = 'fiLgPP0mvZm9yKlQy';
  
      // Get the form data
      const formData = new FormData(e.target);
      const data = {
        from_name: formData.get('name'),
        from_email: formData.get('email'),
        message: formData.get('message'),
      };
  
      // Send the email
      emailjs.send(serviceID, templateID, data, userID)
      .then((response) => {
        console.log('Email sent successfully:', response);
        setShowSuccessMessage(true);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
    };
  return (
    <div>
      <Particle />
      <Container fluid className="contact-section">
        <Row style={{ justifyContent: 'center', position: 'relative', textAlign: 'center' }}>
          <Col md={12} style={{ fontSize: '3em', paddingBottom: '20px' }}>
            <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '10px' }} />
            <span className="purple"> Contact </span>
             Me
          </Col>
          <Col md={12} style={{ fontSize: '1.5em', paddingBottom: '10px' }}>
            <p>
              Feel free to drop me a message. I'll get back to you as soon as possible.
            </p>
            <p>
              You can also reach out to me via email at{' '}
              <strong>
                <a href="mailto:harsh23@iitk.ac.in" style={{ color: '#007BFF' }}>
                harsh23@iitk.ac.in
                </a>
              </strong>.
            </p>
          </Col>
        </Row>
        <Row style={{ justifyContent: 'center', position: 'relative', paddingTop: '20px', textAlign: 'left' }}>
          <Col md={6}>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formName"  style={{ marginBottom: '15px' }}>
                <Form.Label>Your Name</Form.Label>
                <Form.Control type="text" name="name" placeholder="Enter your name" required />
              </Form.Group>

              <Form.Group controlId="formEmail" style={{ marginBottom: '15px' }}>
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" name="email" placeholder="Enter your email" required />
              </Form.Group>

              <Form.Group controlId="formMessage" style={{ marginBottom: '15px' }}>
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" name="message" rows={6} placeholder="Enter your message" required />
              </Form.Group>

              <Button variant="primary" type="submit" style={{ marginTop: '10px' }}>
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
        {showSuccessMessage && (
          <Row style={{ justifyContent: 'center', position: 'relative', textAlign: 'center' }}>
            <Col md={12} style={{ fontSize: '1.5em', paddingBottom: '10px' }}>
              <p>Your message has been sent successfully!</p>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}

export default Contact;
