import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi! I'm a MTech Student in the <span className="purple">Department of Management Science (DoMS)</span> at the <span className="purple">IIT Kanpur</span>. I earned my bachelor's in Mechanical Engineering from <span className="purple">MNIT Jaipur</span>, and completed research internships at <span className="purple">IIT Kanpur</span>. 
            <br />
            <br />
            Beyond academics, I enjoy:
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Dancing
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
            <li className="about-activity">
              <ImPointRight /> Exploring new technologies
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
